import { Center } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Footer } from "./Footer";
import { NavbarMenu } from "./NavbarMenu";
import { HomePage } from "../Views/HomePage";
import { Page404 } from "../Views/Page404";
import { BackgroundImage } from "./BackgorundImage";
import "../style.css";
import { WebAplications } from "../Views/WebAplications";
import { Projects } from "../Views/Projects";
import { CV } from "../Views/CV";
import { Contact } from "../Views/Contact";
import { useUserContext } from "./UserContext";
export const Manager = () => {
  const { width } = useUserContext();

  return (
    <BrowserRouter>
      <Center
        minH="100vh"
        color="white"
        flexDir="column"
        justifyContent="space-between"
        fontSize={width < 800 ? "xl" : "xl"}
      >
        <BackgroundImage />
        <NavbarMenu />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/web-aplications" element={<WebAplications />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/cv" element={<CV />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/*" element={<Page404 />} />
        </Routes>
        <Footer />
      </Center>
    </BrowserRouter>
  );
};
