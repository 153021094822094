import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { ImageSlider } from "../Components/ImageSlider";
import { useUserContext } from "../Components/UserContext";

export const WebAplications = () => {
  const { width } = useUserContext();

  const kavarnaImages = [
    "web-aplications/kavarna-1.jpg",
    "web-aplications/kavarna-2.jpg",
    "web-aplications/kavarna-3.jpg",
  ];

  const orjImages = [
    "web-aplications/orjchrudim-1.jpg",
    "web-aplications/orjchrudim-2.jpg",
    "web-aplications/orjchrudim-3.jpg",
    "web-aplications/orjchrudim-4.jpg",
  ];

  const prihlaskyImages = [
    "web-aplications/prihlasky-1.jpg",
    "web-aplications/prihlasky-2.jpg",
    "web-aplications/prihlasky-3.jpg",
  ];

  useEffect(() => {
    document.title = "Martin Bíško - Webové aplikace";
  }, []);

  return (
    <Box
      className={`content ${
        width < 800 ? "mobile" : width < 1350 ? "tablet" : ""
      }`}
    >
      <Heading
        fontSize="5xl"
        mt={width < 800 ? "5rem" : "30vh"}
        mb="5rem"
        textAlign="center"
      >
        Webové aplikace
      </Heading>
      <Text>
        <Box as={"span"} fontSize="3xl">
          Z
        </Box>
        aměřuji se na vývoj webových aplikací. Pokud hledáte někoho, kdo by Vám
        vytvořil osobní stránky, doporučuji využít jiné služby. Pokud hledáte
        někoho, kdo by Vám vytvořil aplikaci s pokročilejšími funkcemi
        (Administráce, správa systému, E-shop), tak máte štěstí, to je přesně
        to, co dělám.
      </Text>
      <Text>Zde se můžete podívat na některé mé projekty.</Text>

      <Box mt="2rem">
        <Flex alignItems="center" flexDir={width < 800 ? "column" : "row"}>
          {width < 800 && (
            <Heading w="100%" textAlign="left">
              Skautská kavárna
            </Heading>
          )}
          <ImageSlider images={kavarnaImages} />
          <Box ml={width < 800 ? "" : "2rem"} w={width < 800 ? "100%" : "40vw"}>
            {width >= 800 && <Heading>Skautská kavárna</Heading>}
            <Text>
              Aplikace Skautská kavárna slouží pro vyřizování objednávek pro
              akci Skautská kavárna pořádána hlineckými skauty. V aplikaci lze
              vytvářet objednávky, které se okamžitě zobrazí i na zařízeních,
              kde se produkt připravuje.
            </Text>
            <Text mt="1rem">
              Dále lze v aplikaci spravovat (přidávat či jinak upravovat a
              mazat) jednotlivé produkty. Lze i zobrazit analytická data jako
              například seznam prodaných produktů.
            </Text>
          </Box>
        </Flex>

        <Flex
          mt="3rem"
          alignItems="center"
          flexDir={width < 800 ? "column" : "row"}
        >
          {width < 800 && (
            <Heading w="100%" textAlign="left">
              Web ORJ Chrudim
            </Heading>
          )}
          {width < 800 && <ImageSlider images={orjImages} />}
          <Box mr={width < 800 ? "" : "2rem"} w={width < 800 ? "100%" : "40vw"}>
            {width >= 800 && <Heading>Web ORJ Chrudim</Heading>}
            <Text>
              Web pro okresní radu Junáka v Chrudimi je dělaný jak pro
              uživatele, kteří chtějí na stránkách získat infarmace, jako jsou
              například informace o střediskách, newsletter nebo třeba kontakty,
              tak i pro uživatele, kteří se aktivně podílejí na rozvoji
              skautingu v okresu. Pro tyto uživatele bylo potřeba vytvořit i
              administrační menu, kde mohou vidět například soukromé dokumenty.
            </Text>
            <Text mt="1rem">
              Součástí webu je i rezervační formulář srubu, který se nachází u
              Slatiňan. Tento formulář dovoluje návštěvníkům webu zarezervovat
              srub a zároveň je pro něj vytvořena administrační část, kde lze
              vidět informace o rezervovaných termínech, lze rezervace
              potvrzovat nebo zamítat.
            </Text>
          </Box>
          {width >= 800 && <ImageSlider images={orjImages} />}
        </Flex>

        <Flex
          mt="3rem"
          alignItems="center"
          flexDir={width < 800 ? "column" : "row"}
        >
          {width < 800 && (
            <Heading w="100%" textAlign="left">
              Přihlášky na tábor
            </Heading>
          )}
          <ImageSlider images={prihlaskyImages} />
          <Box ml={width < 800 ? "" : "2rem"} w={width < 800 ? "100%" : "40vw"}>
            {width >= 800 && <Heading>Přihlášky na tábor</Heading>}
            <Text>
              Od roku 2023 hlinečtí skauti používají pro online přihlášky na
              tábor vlastní systém. Kromě pro všechny návštevníky přístupného
              formuláře pro vyplnění příhlášky svému dítěti se zde nachází i
              administrační menu pro zobrazení výsledků formuláře. Výsledky lze
              zobrazit jako seznam všech přihlášených, po individuálních datech
              nebo je lze exportovat ve formátu csv, který může být pak použit
              například v hromadné korespondenci.
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
