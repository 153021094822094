import { Box } from "@chakra-ui/react";

export const BackgroundImage = () => {
  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      width="100vw"
      height="100%"
      backgroundImage="url('background.jpg')"
      backgroundRepeat="no-repeat"
      backgroundAttachment="fixed"
      backgroundSize="cover"
      zIndex="-1"
    ></Box>
  );
};
