import { Box, Flex, Heading, Link, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { ImageSlider } from "../Components/ImageSlider";
import { useUserContext } from "../Components/UserContext";

export const Projects = () => {
  const { width } = useUserContext();

  const maturitaImages = [
    "projects/dod-1.jpg",
    "projects/dod-2.jpg",
    "projects/dod-3.jpg",
  ];

  const spaceTripImages = [
    "projects/spaceTrip-1.jpg",
    "projects/spaceTrip-2.jpg",
    "projects/spaceTrip-3.jpg",
    "projects/spaceTrip-4.jpg",
    "projects/spaceTrip-5.jpg",
    "projects/spaceTrip-6.jpg",
  ];

  const towerTripImages = [
    "projects/towerTrip-1.jpg",
    "projects/towerTrip-2.jpg",
    "projects/towerTrip-3.jpg",
    "projects/towerTrip-4.jpg",
    "projects/towerTrip-5.jpg",
    "projects/towerTrip-6.jpg",
  ];

  useEffect(() => {
    document.title = "Martin Bíško - Projekty";
  }, []);

  return (
    <Box
      className={`content ${
        width < 800 ? "mobile" : width < 1350 ? "tablet" : ""
      }`}
    >
      <Heading
        fontSize="5xl"
        mt={width < 800 ? "5rem" : "30vh"}
        mb="5rem"
        textAlign="center"
      >
        Projekty
      </Heading>
      <Text>
        <Box as="span" fontSize="3xl">
          K
        </Box>
        romě webových aplikací se rád věnuji i vývoji desktopových aplikací,
        převážně her, ale dokázal bych vytvořit i jiný software na míru. Zde
        jsou příklady her, které jsem vytvořil. Jeden z nich je i můj maturitní
        projekt.
      </Text>

      <Box mt="2rem">
        <Flex alignItems="center" flexDir={width < 800 ? "column" : "row"}>
          {width < 800 && (
            <Heading w="100%" textAlign="left">
              Maturitní projekt
            </Heading>
          )}
          <ImageSlider images={maturitaImages} />
          <Box ml={width < 800 ? "" : "2rem"} w={width < 800 ? "100%" : "40vw"}>
            {width >= 800 && <Heading>Maturitní projekt</Heading>}
            <Text>
              Jako svůj maturitní projekt jsem si zvolil 2D strategickou hru
              postavenou na mechanice mřížky, kde hráč ovládá své charaktery a
              plní různé úkoly, podle jeho preferencí.
            </Text>
            <Text mt="1rem">
              Hra obsahuje soubojový systém, který je rozdělen na tahy, kde
              každý charakter může udělat určitý počet kroků, jednu magickou a
              jednu útočnou akci. Po těchto akcích již nemůže charakter dělat
              žádnou akci a je na řadě další charakter. Pořadí je generované
              náhodně, takže je každý souboj unikátní.
            </Text>
            <Text mt="1rem">
              Hra není ve fázi, kdy by se dala dohrát. Tento maturitní projekt
              slouží jen jako ukázka toho, jak by taková hra mohla vypadat. K
              jejímu dokončení by byl potřeba větší tým a více času.
            </Text>
            <Text mt="1rem">
              Pokud Vás zajímá více, jako například stažení hry či dokumentace
              projektu, klikněte{" "}
              <Link
                href="https://b2019biskma.delta-www.cz/maturita"
                target="_blank"
                fontWeight="bold"
              >
                zde
              </Link>
              .
            </Text>
          </Box>
        </Flex>

        <Flex
          mt="3rem"
          alignItems="center"
          flexDir={width < 800 ? "column" : "row"}
        >
          {width < 800 && (
            <Heading w="100%" textAlign="left">
              Hra Space Trip
            </Heading>
          )}
          {width < 800 && <ImageSlider images={spaceTripImages} />}
          <Box mr={width < 800 ? "" : "2rem"} w={width < 800 ? "100%" : "40vw"}>
            {width >= 800 && <Heading>Hra Space Trip</Heading>}
            <Text>
              Hru Space Trip jsme dělali s kamarádem, který vytvářel grafiku do
              hry. Hru jsme vytvářeli v rámci GameJamu, což je akce, kde se za
              určitou dobu (v našem případě za 7 dní) snažíme vytvořit hru,
              která je hratelná.
            </Text>
            <Text mt="1rem">
              Ve hře Space Trip je hlavním cílem za co nejkratší čas sesbírat
              všechny předměty pro přežití. Ve vesmírné lodi totiž začal unikat
              jedovatý plyn a hráč má omezený čas.
            </Text>
            <Text mt="1rem">
              Pokud byste si chtěli hru vyzkoušet, můžete ji na jít na portálu{" "}
              <Link
                href="https://yarynator.itch.io/spacetrip?secret=kxTl3scOFpVRYnpdUwFIlLQSUaQ"
                target="_blank"
                fontWeight="bold"
              >
                itch.io
              </Link>
              .
            </Text>
          </Box>
          {width >= 800 && <ImageSlider images={spaceTripImages} />}
        </Flex>

        <Flex
          mt="3rem"
          alignItems="center"
          flexDir={width < 800 ? "column" : "row"}
        >
          {width < 800 && (
            <Heading w="100%" textAlign="left">
              Hra Tower Trip
            </Heading>
          )}
          <ImageSlider images={towerTripImages} />
          <Box ml={width < 800 ? "" : "2rem"} w={width < 800 ? "100%" : "40vw"}>
            {width >= 800 && <Heading>Hra Tower Trip</Heading>}
            <Text>
              Hra Tower Trip je další výtvor, který jsme vytvořili s kamarádem
              na dalším ročníku GameJamu, kde jsme opět měli za 7 dnů vytvořit
              funkční hru.
            </Text>
            <Text mt="1rem">
              V této hře se nachází několik logických úkolů a hádanek, kterými
              hráč prochází od začátku až do konce. Hadanky na sebe navazují,
              takže hráč lineárně postupuje hrou.
            </Text>
            <Text mt="1rem">
              Pokud byste si chtěli hru vyzkoušet, můžete ji na jít na portálu{" "}
              <Link
                href="https://yarynator.itch.io/tower-trip"
                target="_blank"
                fontWeight="bold"
              >
                itch.io
              </Link>
              .
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
