import { Center, Heading, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import Typewriter from "typewriter-effect";

export const HomePage = () => {
  useEffect(() => {
    document.title = "Martin Bíško";
  }, []);

  return (
    <Center flexDir="column" overflow="hidden">
      {/* <Menu>
            <MenuButton position="absolute" top="1rem" left="1rem" as={IconButton} icon={<HamburgerIcon />} />
            <MenuList>
                <MenuItem><Link href="https://b2019biskma.delta-www.cz/maturita" target="_blank">Maturitní projekt</Link></MenuItem>
            </MenuList>
        </Menu> */}
      {/* <Image
        position="absolute"
        left="0"
        top="50vh"
        transform="translateY(-50%)"
        w="100vw"
        src="logo192.png"
        userSelect="none"
      /> */}

      <Heading display="flex" flexDir="row" fontSize="6xl">
        <Text mr="1rem">Martin Bíško: </Text>
        <Typewriter
          onInit={(typewriter) => {
            typewriter
              .typeString("Programmer")
              .pauseFor(2000)
              .deleteAll()
              .typeString("Developer")
              .pauseFor(2000)
              .deleteAll()
              .typeString("Freelancer")
              .pauseFor(2000)
              .start();
          }}
          options={{ loop: true }}
        />
      </Heading>
      <Text mt="1rem" fontSize="3xl">
        Programy, Weby, Aplikace, Hry
      </Text>
    </Center>
  );
};
