import { createContext, useContext, useEffect, useState } from "react";

export const UserContext = createContext({
  width: 0,
});

export const UserContextProvider = (props: any) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const resize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", resize);
    resize();
  }, []);

  return (
    <UserContext.Provider value={{ width }}>
      {props.children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};
