import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Flex,
  Text,
  Box,
  Image,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useUserContext } from "./UserContext";

export const NavbarMenu = () => {
  const { width } = useUserContext();

  return (
    <Flex p="1rem" w="90vw" justifyContent="space-between" alignItems="center">
      <Link to="/">
        <Flex alignItems="center">
          <Image src="/logo.png" w="50px" />
          <Text ml="1rem" fontSize="3xl">
            Yarynator
          </Text>
        </Flex>
      </Link>
      {width > 800 ? (
        <Box className="menu-container">
          <Link to="/web-aplications">Webové aplikace</Link>
          <Link to="/projects">Projekty</Link>
          <Link to="/cv">Životopis</Link>
          <Link to="/contact">Kontakt</Link>
        </Box>
      ) : (
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Menu"
            icon={<HamburgerIcon />}
            variant="outline"
          />
          <MenuList color="black" fontSize="medium">
            <Link to="/web-aplications">
              <MenuItem>Webové aplikace</MenuItem>
            </Link>
            <Link to="/projects">
              <MenuItem>Projekty</MenuItem>
            </Link>
            <Link to="/cv">
              <MenuItem>Životopis</MenuItem>
            </Link>
            <Link to="/contact">
              <MenuItem>Kontakt</MenuItem>
            </Link>
          </MenuList>
        </Menu>
      )}
    </Flex>
  );
};
