import { Box, Image } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useUserContext } from "./UserContext";

interface Props {
  images: string[];
}

export const ImageSlider: React.FC<Props> = (props) => {
  const { width } = useUserContext();

  const [index, setIndex] = useState(0);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      setIndex((i) => (i + 1) % props.images.length);
    }, 5000);

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <Box className="slideshow" w={width < 800 ? "100%" : "100%"}>
      <Box
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {props.images.map((image, index) => (
          <Image className="slide" key={`slideimage${index}`} src={image} />
        ))}
      </Box>
      <Box className="slideshowDots">
        {props.images.map((_, idx) => (
          <Box
            key={`slidedot${idx}`}
            className={`slideshowDot${idx === index ? " active" : ""}`}
            onClick={() => setIndex(idx)}
          />
        ))}
      </Box>
    </Box>
  );
};
