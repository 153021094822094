import { Center, Heading, Text } from "@chakra-ui/react";
import { useEffect } from "react";

export const Page404 = () => {
  useEffect(() => {
    document.title = "Martin Bíško - Page Not Found";
  }, []);

  return (
    <Center flexDir="column">
      <Heading fontSize="5xl">Page Not Found</Heading>
      <Text fontSize="2xl" mt="1rem">
        Stránka nebyla nalezena
      </Text>
    </Center>
  );
};
