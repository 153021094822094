import { Box, Flex, Heading, Link, Table, Td, Th, Tr } from "@chakra-ui/react";
import { useEffect } from "react";
import { useUserContext } from "../Components/UserContext";
import { CiFacebook, CiLinkedin } from "react-icons/ci";

export const Contact = () => {
  const { width } = useUserContext();

  useEffect(() => {
    document.title = "Martin Bíško - Kontakt";
  }, []);

  return (
    <Box
      className={`content ${
        width < 800 ? "mobile" : width < 1350 ? "tablet" : ""
      }`}
    >
      <Heading fontSize="5xl" textAlign="center" m="5rem auto">
        Kontakt
      </Heading>
      <Table>
        <Tr>
          <Td>Jméno:</Td>
          <Td>Martin Bíško</Td>
        </Tr>
        <Tr>
          <Td>IČO:</Td>
          <Td>19449411</Td>
        </Tr>
        <Tr>
          <Td>Telefon:</Td>
          <Td>+420 604 349 345</Td>
        </Tr>
        <Tr>
          <Td>E-mail:</Td>
          <Td>info@yarynator.dev</Td>
        </Tr>
        <Tr>
          <Td></Td>
          <Td>spoluprace@yarynator.dev</Td>
        </Tr>
        <Tr>
          <Td>ID datové schránky</Td>
          <Td>vrknif5</Td>
        </Tr>
        <Tr>
          <Td>Adresa</Td>
          <Td>Stan 29, 539 01 Vítanov</Td>
        </Tr>
      </Table>

      <Flex mt="1rem" justifyContent="center" gap={5}>
        <Link href="https://www.facebook.com/bisko.martin/" target="_blank">
          <CiFacebook className="icon" />
        </Link>
        <Link
          href="https://www.linkedin.com/in/martin-b%C3%AD%C5%A1ko-082745262/"
          target="_blank"
        >
          <CiLinkedin className="icon" />
        </Link>
      </Flex>
    </Box>
  );
};
