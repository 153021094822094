import { Box, Heading, Table, Td, Tr } from "@chakra-ui/react";
import { useEffect } from "react";
import { useUserContext } from "../Components/UserContext";

export const CV = () => {
  const { width } = useUserContext();

  useEffect(() => {
    document.title = "Martin Bíško - Životopis";
  }, []);

  return (
    <Box
      className={`content ${
        width < 800 ? "mobile" : width < 1350 ? "tablet" : ""
      }`}
    >
      <Heading m="5rem auto" fontSize="5xl" textAlign="center">
        Životopis
      </Heading>
      <Heading fontSize="3xl">Vzdělání</Heading>
      <Table>
        <Tr>
          <Td w={width < 800 ? "" : "300px"}>2023 - současnost</Td>
          <Td>
            Vysoké učení technické v Brně - Fakulta informačních technologií
          </Td>
        </Tr>
        <Tr>
          <Td>2019 - 2023</Td>
          <Td>
            DELTA - Střední škola informatiky a ekonomie, s.r.o.{" "}
            <Box as="span" fontWeight="bold">
              (vzdělání s maturitou)
            </Box>
          </Td>
        </Tr>
        <Tr>
          <Td>2010 - 2019</Td>
          <Td>
            ZŠ Ležáků Hlinsko{" "}
            <Box as="span" fontWeight="bold">
              (základní vzdělání)
            </Box>
          </Td>
        </Tr>
      </Table>

      <Heading mt="3rem" fontSize="3xl">
        Praxe
      </Heading>
      <Table>
        <Tr>
          <Td w={width < 800 ? "" : "300px"}>Červen 2023 - současnost</Td>
          <Td>Freelo Bay s.r.o. - Nástroj na správu projektů a úkolů</Td>
        </Tr>
        <Tr>
          <Td>Červenec 2022 </Td>
          <Td>
            Technologické centrum Hradec Králové - Vedení kroužku programování
            pro děti základních škol
          </Td>
        </Tr>
        <Tr>
          <Td>Květen 2022</Td>
          <Td>Experia Group - 14 denní praxe jako programátor</Td>
        </Tr>
        <Tr>
          <Td>Leden 2022 - Červen 2022</Td>
          <Td>
            Modra 3D - Vývoj aplikace pro architekty nábytku pro generování 3D
            modelů a nákresů
          </Td>
        </Tr>
        <Tr>
          <Td>Září 2021 - Květen 2022</Td>
          <Td>
            eDelta - Vedení kroužku programování pro děti 8. a 9. tříd základní
            školy
          </Td>
        </Tr>
        <Tr>
          <Td>Červenec 2021</Td>
          <Td>
            Technologické centrum Hradec Králové - Vedení kroužku programování
            pro děti základních škol
          </Td>
        </Tr>
      </Table>

      <Heading mt="3rem" fontSize="3xl">
        Dovednosti
      </Heading>
      <Table>
        <Tr>
          <Td w={width < 800 ? "" : "300px"}>Technologie</Td>
          <Td>
            C#, TypeScript, základy PHP a C++, Unity, React, VueJS, MongoDB,
            MySQL
          </Td>
        </Tr>
        <Tr>
          <Td>Certifikáty</Td>
          <Td>
            ECDL, Cisco - CCNA: Switching, Routing, and Wireless Essentials,
            Cisco - CCNA: Introduction to Networks, Cisco - IT Essentials
          </Td>
        </Tr>
      </Table>

      <Heading mt="3rem" fontSize="3xl">
        Zájmy
      </Heading>
      <Table>
        <Tr>
          <Td w={width < 800 ? "" : "300px"}>Junák - český skaut, z.s.</Td>
          <Td>Skautský vedoucí IV. oddílu skautů</Td>
        </Tr>
        <Tr>
          <Td>Četba</Td>
          <Td>Fantasy a Sci-Fi</Td>
        </Tr>
        <Tr>
          <Td>Hudba</Td>
          <Td>Hra na klavír - absolvent 1. stupně základního studia</Td>
        </Tr>
      </Table>
    </Box>
  );
};
